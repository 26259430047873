import React from 'react';
import PropTypes from 'prop-types';
import FingoStack from '@fingo/lib/components/stacks/FingoStack';
import AutoCompleteMasterEntity from '@fingo/lib/components/inputs/AutoCompleteMasterEntity';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import { MoneyInput } from '@fingo/lib/components/inputs';

const WIDTH = 200;

const CreateSimulationHeader = ({
  setClient,
  setDebtor,
  setAmount,
  amount,
  setDays,
  onAdd,
  onClean,
  loading,
  invoices,
}) => (
  <FingoStack>
    <Box width={WIDTH}>
      <AutoCompleteMasterEntity
        setEntity={setClient}
        label="Cliente"
        textFieldProps={{ variant: 'outlined' }}
        InputProps={{ bgcolor: 'white' }}
        readOnly={invoices.length > 0}
        height={50}
      />
    </Box>
    <Box width={WIDTH}>
      <AutoCompleteMasterEntity
        setEntity={setDebtor}
        label="Deudor"
        textFieldProps={{ variant: 'outlined' }}
        height={50}
      />
    </Box>
    <Box width={WIDTH}>
      <FormControl fullWidth variant="outlined">
        <MoneyInput
          setValue={setAmount}
          value={amount}
          label="Monto"
          size="small"
          variant="outlined"
          fullWidth
          sx={{ height: 50 }}
        />
      </FormControl>
    </Box>
    <Box width={WIDTH}>
      <FormControl fullWidth variant="outlined">
        <TextField
          fullWidth
          onChange={setDays}
          label="Días plazo"
          type="number"
          size="small"
        />
      </FormControl>
    </Box>
    <LoadingButton
      variant="contained"
      size="small"
      onClick={onAdd}
      loading={loading}
    >
      Agregar factura
    </LoadingButton>
    <Button
      variant="outlined"
      size="small"
      onClick={onClean}
    >
      Limpiar
    </Button>
  </FingoStack>
);

CreateSimulationHeader.propTypes = {
  setClient: PropTypes.func.isRequired,
  setDebtor: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  setDays: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onClean: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default CreateSimulationHeader;
